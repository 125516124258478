import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { dateToUTC } from '@/app/utils/dates';
import { showNotification } from 'app/actions';
import { ChangeFileStorageNodeModel, FileStorageNodeSchema } from '@/generated';
import { DOCUMENTS_REQUEST_QUERY_PARAMS, FILE_STORAGE, FLEET } from '@/pages/documents/const';
import { setDocumentsSectionList, setSelectedNode } from '@/pages/documents/DocumentsList/actions';
import { getCurrentItems, getSelectedNode } from '@/pages/documents/DocumentsList/selector';
import { FileTreeNode, IDocumentInfoFormValues } from '@/pages/documents/types';
import { all } from '@redux-saga/core/effects';
import isNil from 'lodash-es/isNil';
import { Action } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { asMutable } from '@core-ui/immutable';
import * as actions from './actions';

function* updateDocumentInfo({ payload }: Action<IDocumentInfoFormValues>) {
  try {
    const { name, expires_at, description } = payload;

    yield put(actions.setDocumentsInfoLoading({ loading: true }));

    const boatId: number = yield select(getCurrentBoatId);
    const node: Nullable<FileTreeNode> = yield select(getSelectedNode);
    const currentItems: Nullable<FileTreeNode[]> = yield select(getCurrentItems);
    const mutableCurrentItems = asMutable(currentItems, { deep: true });

    const formattedNewName = name.trim();
    const updatedItemIndex = mutableCurrentItems?.findIndex((item) => item.id === node?.id);

    if (mutableCurrentItems && !isNil(updatedItemIndex) && !isNil(node?.id) && node?.data) {
      const url = buildURL(`/${FILE_STORAGE}/${node.id}`, {
        [DOCUMENTS_REQUEST_QUERY_PARAMS.BOAT_ID]: boatId,
      });

      const updatedNodeName = `${formattedNewName}.${node.text.split('.').pop()}`;

      const updatedNode: FileStorageNodeSchema = yield call(PATCH, url, {
        text: updatedNodeName,
        data: {
          expires_at: expires_at ? dateToUTC(expires_at).getTime() : null,
          description: description || null,
        },
      } as ChangeFileStorageNodeModel);

      const formattedUpdatedNode: FileTreeNode = {
        ...updatedNode,
        droppable: updatedNode.data.file_type === 'folder',
        parent: updatedNode.data.shared && updatedNode.parent === 0 ? FLEET : updatedNode.parent,
      };

      mutableCurrentItems[updatedItemIndex] = formattedUpdatedNode;

      yield all([
        put(
          setDocumentsSectionList({
            value: mutableCurrentItems,
            hasData: true,
            isAllDataReceived: true,
          })
        ),
        put(setSelectedNode(updatedNode)),
        put(
          showNotification({
            variant: 'success',
            title: 'notification.success.text.update_document',
          })
        ),
      ]);
    }
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setDocumentsInfoLoading({ loading: false }));
  }
}

export default [takeLatest(actions.updateDocumentInfo, updateDocumentInfo)];
