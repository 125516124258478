import app from './app/reducer';
import loadFiles from './components/LoadFileDropzone/reducer';
import dictionary from './dictionary/reducer';
import analytics from './pages/analytics/reducer';
import backoffice from './pages/backoffice/reducer';
import chats from './pages/chats/reducer';
import documents from './pages/documents/reducer';
import finances from './pages/finances/reducer';
import login from './pages/login/reducer';
import settings from './pages/settings/reducer';

export default {
  app,
  login,
  backoffice,
  chats,
  analytics,
  dictionary,
  loadFiles,
  finances,
  settings,
  documents,
};
