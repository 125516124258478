import { Router } from '@core-ui/redux-router';
import { getAppReady } from '@/app/selectors';
import State from '@/app/types/state';
import { Location } from 'history';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { Action } from 'redux-actions';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { initApp } from './app/actions';
import history from './history';
import routes from './pages/routes';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

let router: Router<Store<any, Action<any>>>;

const sagaMiddleware = createSagaMiddleware();
// trace can impact dev server performance, disable it if you have issues
const composeEnhancers = composeWithDevTools(
  process.env.NODE_ENV !== 'production'
    ? {
        trace: true,
        traceLimit: 50,
      }
    : {}
);

const configureStore = () => {
  if (router) {
    return router.store;
  }

  sagaMiddleware.setContext({ history });

  const initialState = {};

  const store = createStore<State, Action<unknown>, unknown, unknown>(
    // store has a State type, but we use Immutable<State>, so we ingore this ts error
    // @ts-ignore
    combineReducers(rootReducer),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  router = new Router(store, rootReducer, sagaMiddleware, history, routes);

  let isAppReady = false;
  let location: Location | undefined;

  const update = () => {
    if (isAppReady && location) {
      router.runActions();
    }
  };

  const updater = {
    setAppReady: (ready: boolean) => {
      isAppReady = ready;
      update();
    },
    setLocation: (loc: Location) => {
      location = loc;
      update();
    },
  };

  const unsubscribe = store.subscribe(() => {
    const isAppReady = getAppReady(store.getState());

    if (isAppReady) {
      unsubscribe();
      updater.setAppReady(isAppReady);
    }
  });

  updater.setLocation(history.location);
  history.listen((location) => {
    updater.setLocation(location);
  });

  let task = router.injectSaga('root', rootSaga);

  store.dispatch(initApp());

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const reducer = require('./rootReducer').default;
      router.replaceReducer(reducer(history));
    });

    module.hot.accept('./rootSaga', () => {
      task.cancel();
      task.toPromise().then(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        task = sagaMiddleware.run(require('./rootSaga').default);
      });
    });
  }

  return store;
};

export { configureStore, router };
