import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useIntl } from 'components/Intl';

const ListSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      gap={3}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
      <Skeleton height={22} width="100%" />
    </Grid>
  );
};

export default ListSkeleton;
