import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useIntl } from 'components/Intl';

const DocumentInfoSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      gap={4}
      padding={6}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
      <Skeleton height={48} width="100%" />
    </Grid>
  );
};

export default DocumentInfoSkeleton;
